import React from "react";
import { ContainerUniversities, CallStudent } from "./style";
import enem from "../../assets/images/enem.png";
import fuvest from "../../assets/images/fuvest.png";
import uniube from "../../assets/images/uniube.png";
import unesp from "../../assets/images/unesp.png";
import unicamp from "../../assets/images/unicamp.png";
import ufu from "../../assets/images/ufu.png";

export default function UniversitiesHome() {
  return (
    <>
      <ContainerUniversities>
        <img src={enem} alt="Enem" />
        <img src={fuvest} alt="Fuvest" />
        <img src={uniube} alt="Uniube" />
        <img src={unesp} alt="Unesp" />
        <img src={unicamp} alt="Unicamp" />
        <img src={ufu} alt="Ufu" />
      </ContainerUniversities>

      <CallStudent>
        <a href="#subscribe">QUERO UMA VAGA NO CURSO LIGANDO IDEIAS!</a>
      </CallStudent>
    </>
  );
}
