import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";
import { phoneIsValid } from "../../validators/phone";

export default function InputPhone({
  name,
  label,
  control,
  rules,
  errors,
  variant,
  defaultValue,
}) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          validate: phoneIsValid,
        }}
        defaultValue={defaultValue ? defaultValue : ""}
        render={({ field }) => (
          <InputMask
            {...field}
            mask="(99) 9 9999-9999"
            disabled={false}
            maskChar="_"
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                variant={variant ? variant : "outlined"}
                fullWidth
                autoComplete="off"
                error={errors ? true : false}
                required={rules && rules.required ? rules.required : false}
                label={label}
                helperText={
                  errors && errors.type === "required"
                    ? "Campo obrigatório"
                    : errors && errors.type === "validate"
                    ? "Telefone Inválido"
                    : ""
                }
                type="text"
              />
            )}
          </InputMask>
        )}
      />
    </>
  );
}
