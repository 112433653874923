import React from "react";
import { ContentLeftSideHome, Subscribe } from "./style";
import arrowUp from "../../assets/images/arrow-up.png";
import arrowDown from "../../assets/images/arrow-down.png";

export default function LeftSideHome() {
  return (
    <ContentLeftSideHome>
      <p>
        <strong>Independente do seu nível de escrita,</strong> o curso on-line
        do
        <strong> Ligando Ideias Redação alavanca sua nota do Enem</strong> e dos
        <strong> vestibulares</strong>. Vai prestar{" "}
        <strong>Uniube, Fuvest, Unesp, IMEPAC, Uni-FACEF, UFU </strong> ou
        <strong> quer garatir seu 900+?</strong>
      </p>

      <Subscribe>
        <h1>
          Inscreva-se <br /> para 2025!
        </h1>

        <p>
          Veja como o Ligando Ideias vai lhe ajudar a realizar o sonho de
          ingressar na universade:
        </p>

        <img id="arrow-up" src={arrowUp} alt="Inscreva-se para 2024!" />

        <img id="arrow-down" src={arrowDown} alt="Inscreva-se para 2024!" />
      </Subscribe>
    </ContentLeftSideHome>
  );
}
