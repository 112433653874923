import styled from "styled-components";

export const ContainerWhyRegister = styled.div`
  margin-top: 7.5rem;
  position: relative;
  .bg {
    background: #2f3a8f;
    height: 274px;
  }

  .content {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .wrapper-boxes {
      display: flex;
      justify-content: space-evenly;

      > div:not(:last-child) {
        margin-right: 25px;
      }
    }
  }

  @media (max-width: 600px) {
    margin-top: 3rem;

    .bg {
      display: none;
    }

    .content {
      position: initial;
    }

    .wrapper-boxes {
      flex-direction: column;

      > div:not(:last-child) {
        margin-right: 0 !important;
        margin-bottom: 2rem;
      }
    }
  }

  @media (min-width: 601px) and (max-width: 889px) {
    margin-top: 3rem;

    .bg {
      display: none;
    }

    .content {
      position: initial;
    }

    .wrapper-boxes {
      flex-direction: column;

      > div:not(:last-child) {
        margin-right: 0 !important;
        margin-bottom: 2rem;
      }
    }
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    margin-top: 5rem;

    .bg {
      display: none;
    }

    .content {
      position: initial;
    }

    .wrapper-boxes {
      > div:not(:last-child) {
        margin-right: 20px !important;
      }
    }
  }
`;

export const WhyRegisterText = styled.div`
  padding: 2rem;
  color: white;
  background: #2f3a8f;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  p {
    margin-bottom: 0;
  }

  h1 {
    margin-top: 0;
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    padding: 1rem;
  }
`;

export const BoxWhiteItem = styled.div`
  background: white;
  padding: 2rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  p {
    margin-bottom: 0;
    color: gray;
    margin-top: 0;
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    padding: 1rem;
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    text-align: center;
  }
  svg {
    color: #2f3a8f;
    font-size: 55px;
  }
`;
