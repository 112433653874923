import styled from "styled-components";

export const ContentRightSideHome = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  form {
    padding: 3.5rem 1.5rem 2rem 1.5rem;
    border-right: 1px solid #2f3a8f;
    border-left: 1px solid #2f3a8f;
    border-bottom: 1px solid #2f3a8f;
    margin-top: -15px;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
  }
`;

export const Plataform = styled.div`
  margin-top: 3rem;
  position: relative;

  p {
    font-size: 14px;
    margin-bottom: 1.5rem;
    margin-top: 0;
    background: #ffe103;
    display: flex;
    padding: 0.8rem;
    color: #2f3a8f;
    font-weight: 900;
    border-radius: 17px;
    padding-left: 1.5rem;
  }

  img {
    position: absolute;
    top: -69px;
    right: -20px;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      position: initial;
    }
    p {
      text-align: center;
    }
  }

  @media (min-width: 601px) and (max-width: 889px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      position: initial;
    }

    p {
      padding-left: 12.8px;
    }
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      position: initial;
    }

    p {
      padding-left: 12.8px;
    }
  }
`;

export const HeaderForm = styled.div`
  background-color: #2f3a8f;
  padding: 1rem;
  border-radius: 17px;

  p {
    margin: 0 6rem;
    color: #ffe103;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    p {
      margin: 0 1rem;
    }
  }

  @media (min-width: 601px) and (max-width: 889px) {
    p {
      margin: 0 1rem;
    }
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    p {
      margin: 0 2rem;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background-color: #2f3a8f !important;
    color: white !important;
    margin-top: 2rem;
    font-weight: bold;
    border-radius: 17px;
  }
`;

export const ValidateRecaptcha = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;
