import styled from "styled-components";

export const ContentLeftSideHome = styled.div`
  color: gray;

  p {
    font-size: 1.6rem;
    line-height: 37px;
    font-weight: 300;
  }

  h1 {
    color: #2f3a8f;
    text-align: center;
    font-size: 55px;
    font-weight: bold;
    line-height: 60px;
  }
`;

export const Subscribe = styled.div`
  position: relative;

  p {
    text-align: right;
    padding: 0px 5rem;
  }

  #arrow-up {
    position: absolute;
    right: 0;
    top: 0;
  }

  #arrow-down {
    position: absolute;
    left: 35px;
    bottom: -50px;
  }

  @media (max-width: 600px) {
    p {
      text-align: center;
      padding: 0px 1rem;
    }
    #arrow-up {
      display: none;
    }

    #arrow-down {
      display: none;
    }
  }

  @media (min-width: 601px) and (max-width: 889px) {
    p {
      text-align: center;
      padding: 0px 1rem;
    }
    #arrow-up {
      display: none;
    }

    #arrow-down {
      display: none;
    }
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    #arrow-up {
      display: none;
    }

    #arrow-down {
      display: none;
    }
  }
`;
