import styled from "styled-components";

export const ContainerPlans = styled.div`
  margin-top: 3rem;

  .card-plan {
    padding: 3rem;
    position: relative;
    overflow: initial;
    min-height: 457px;
  }

  @media (max-width: 600px) {
    min-height: initial;
  }

  @media (min-width: 601px) and (max-width: 889px) {
    .card-plan {
      padding: 2rem;
      min-height: 649.5px;
    }
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    .card-plan {
      padding: 2rem;
      min-height: 615px;
    }
  }
`;

export const Title = styled.h1`
  text-align: center;
  color: gray;
  margin-bottom: 4.5rem;
  font-size: 36px;

  @media (max-width: 600px) {
    margin-bottom: 3rem;
  }

  @media (min-width: 601px) and (max-width: 889px) {
    margin-bottom: 3rem;
  }
`;

export const IconPlan = styled.div`
  background: #ffe103;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #2f3a8f;

  svg {
    font-size: 40px;
    color: #2f3a8f;
  }

  @media (max-width: 600px) {
    position: initial;
  }

  @media (min-width: 601px) and (max-width: 889px) {
    position: initial;
  }
`;

export const TitlePlan = styled.p`
  color: #2f3a8f;
  font-weight: 900;
  text-align: center;
  font-size: 18px;

  @media (min-width: 900px) and (max-width: 1199px) {
    margin-top: 2.5rem;
  }
`;

export const DescriptionPlan = styled.p`
  color: black;
  font-size: 18px;
  margin-bottom: 2rem;
  font-weight: 300;
`;

export const ItensPlan = styled.div`
  p {
    color: black;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 5px;
    font-weight: 300;
  }
`;

export const PricePlan = styled.div`
  background: #2f3a8f;
  border-radius: 17px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -25.5px;
  color: #ffe103;
  font-weight: 900;

  p {
    margin: 0;
  }

  @media (max-width: 600px) {
    position: initial;
    margin-top: 3rem;
  }

  @media (min-width: 601px) and (max-width: 889px) {
    position: initial;
    margin-top: 3rem;
  }
`;
