import styled from "styled-components";

export const Title = styled.h1`
  text-align: center;
  color: gray;
  margin-bottom: 3rem;
  margin-top: 5rem;
  font-size: 36px;
`;

export const ItemTestimony = styled.div`
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
  }

  .testimony-text {
    min-height: 189px;
  }

  @media (max-width: 600px) {
    margin-bottom: 3rem;
    .testimony-text {
      min-height: initial;
    }
  }

  @media (min-width: 601px) and (max-width: 889px) {
    margin-bottom: 3rem;
    .testimony-text {
      min-height: initial;
    }
  }
`;

export const InfoStudent = styled.div`
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;

  img {
    margin-right: 1.5rem;
    border-radius: 53px;
  }

  @media (max-width: 600px) {
    align-items: flex-start;
  }

  @media (min-width: 601px) and (max-width: 889px) {
    align-items: flex-start;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: gray;
    &:first-child {
      margin-bottom: 5px;
      font-weight: 900;
    }
  }
`;
