import React from "react";
import { Title, ItemTestimony, InfoStudent, Description } from "./style";
import { Grid } from "@mui/material";
import ligia from "../../assets/images/ligia.png";
import duda from "../../assets/images/duda-oliveira.png";

export default function TestimonyHome() {
  return (
    <>
      <Title>DEPOIMENTOS</Title>
      <Grid container columnSpacing={10}>
        <Grid item sxs={12} sm={12} md={6}>
          <ItemTestimony>
            <p>
              <strong>" </strong>
              Em um momento tão importante na minha vida como o vestibular, a
              professora Lays, com toda dedicação, disposição e atenção que teve
              comigo (como ótima profissional que é) foi de grande ajuda para
              mim no desenvolvimento da escrita e na busca de repertórios para
              estruturar uma redação. Não foi à toa que após algumas semanas
              vieram os resultados desses estudos: minha aprovação na USP. Só
              tenho agradecimentos a ela e ao curso por essa conquista!
              <strong> "</strong>
            </p>

            <InfoStudent>
              <img src={ligia} alt="LÍGIA M. CAMPOS JULIANO" />

              <Description>
                <p>LÍGIA M. CAMPOS JULIANO</p>
                <p>APROVADA NA USP SÃO CARLOS</p>
              </Description>
            </InfoStudent>
          </ItemTestimony>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ItemTestimony>
            <p className="testimony-text">
              <strong>" </strong>Ao longo do ano, consegui evoluir muito na
              escrita do meu texto, e ter tirado 960 na redação só comprova que
              todo o esforço e todas as aulas valeram muito a pena. Eu só tenho
              a agradecer por ter me acompanhado, acreditado em mim e feito
              parte dessa evolução. Espero que daqui pra frente possa ser cada
              vez melhor e que esse crescimento seja constante. Muito obrigada!
              <strong> "</strong>
            </p>

            <InfoStudent>
              <img src={duda} alt="" />

              <Description>
                <p>MARIA EDUARDA OLIVEIRA</p>
                <p>960 NA REDAÇÃO DO ENEM</p>
              </Description>
            </InfoStudent>
          </ItemTestimony>
        </Grid>
      </Grid>
    </>
  );
}
