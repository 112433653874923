import React from "react";
import {
  ContainerWhyRegister,
  WhyRegisterText,
  BoxWhiteItem,
  HeaderBox,
} from "./style";
import { Grid, Container } from "@mui/material";
import WebIcon from "@mui/icons-material/Web";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

export default function WhyRegisterHome() {
  return (
    <ContainerWhyRegister>
      <div className="bg"></div>

      <div className="content">
        <Container fixed>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={4}>
              <WhyRegisterText>
                <h1>Por que devo me matricular?</h1>

                <p>
                  O conhecimento é o seu maior aliado seja no vestibular ou no
                  Enem. E tirar uma boa nota na redação pode fazer a maior
                  diferença entre conseguir ou não a tão almejada vaga no Ensino
                  Superior. Portanto, matricular-se no Ligando Ideias será um
                  grande passo para a realização do seu sonho.
                </p>
              </WhyRegisterText>
            </Grid>

            <Grid item xs={12} sm={12} md={8}>
              <div className="wrapper-boxes">
                <BoxWhiteItem>
                  <HeaderBox>
                    <WebIcon />
                    <h3>Plataforma de correção própria</h3>
                  </HeaderBox>
                  <p>
                    Desenvolvida por nós, ela conta com área do aluno exclusiva,
                    folha de redação própria e local reservado para a correção
                    dos textos.
                  </p>
                </BoxWhiteItem>

                <BoxWhiteItem>
                  <HeaderBox>
                    <Filter9PlusIcon />
                    <h3>A certeza de ser um aluno 900+</h3>
                  </HeaderBox>
                  <p>
                    A nossa metodologia faz com que nossos alunos saiam do
                    básico e atinjam o nível avançado na redação em pouco tempo.
                  </p>
                </BoxWhiteItem>

                <BoxWhiteItem>
                  <HeaderBox>
                    <LocalAtmIcon />
                    <h3>Valores exclusivos e acessíveis</h3>
                  </HeaderBox>
                  <p>
                    Criamos quatro planos diferentes para que você consiga
                    escolher aquele que se encaixa melhor na sua realidade.
                  </p>
                </BoxWhiteItem>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ContainerWhyRegister>
  );
}
