import React from "react";
import { Card, Grid } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import {
  ContainerPlans,
  Title,
  IconPlan,
  TitlePlan,
  DescriptionPlan,
  ItensPlan,
  PricePlan,
} from "./style";

export default function PlansHome() {
  return (
    <ContainerPlans>
      <Title>QUATRO MODALIDADES</Title>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className="card-plan">
            <IconPlan>
              <PersonIcon />
            </IconPlan>

            <TitlePlan>
              AULA INDIVIDUAL <br />
              AO VIVO
            </TitlePlan>

            <DescriptionPlan>
              Aulas que se adequam ao seu ritmo e a sua individualidade.
            </DescriptionPlan>

            <ItensPlan>
              <p>• Proposta de redação semanal</p>
              <p>• Material teórico próprio</p>
              <p>• Plataforma de correção própria</p>
              <p>
                • Acompanhamento via WhatsApp para solucionar todas suas dúvidas
              </p>
            </ItensPlan>

            <PricePlan>
              <p>R$ 340,00 / mês</p>
            </PricePlan>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className="card-plan">
            <IconPlan>
              <GroupIcon />
            </IconPlan>

            <TitlePlan>
              TURMA NOTURNA <br />
              AO VIVO
            </TitlePlan>

            <DescriptionPlan>
              Aulas em grupo de no máximo oito pessoas, às 19h30m, em um dia a
              ser definido em conjunto.
            </DescriptionPlan>

            <ItensPlan>
              <p>• Proposta de redação semanal</p>
              <p>• Material teórico próprio</p>
              <p>• Plataforma de correção própria</p>
              <p>
                • Acompanhamento via WhatsApp para solucionar todas suas dúvidas
              </p>
            </ItensPlan>

            <PricePlan>
              <p>R$ 240,00 / mês</p>
            </PricePlan>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className="card-plan">
            <IconPlan>
              <VolumeUpIcon />
            </IconPlan>

            <TitlePlan>
              CORREÇÃO <br />
              HUMANIZADA
            </TitlePlan>

            <DescriptionPlan>
              É direcionada para quem já possui o domínio da técnica e precisa
              apenas da correção das redações.
            </DescriptionPlan>

            <ItensPlan>
              <p>
                • Via áudios detalhados enviados na nossa plataforma própria
              </p>
              <p>• Esclarecimento de dúvidas pelo WhatsApp</p>
              <p>• Sem uso de robôs para corrigir as redações</p>
              <p>• 4 correções por mês</p>
            </ItensPlan>

            <PricePlan>
              <p>R$ 100,00 / mês</p>
            </PricePlan>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className="card-plan">
            <IconPlan>
              <CoPresentIcon />
            </IconPlan>

            <TitlePlan>MENTORIA</TitlePlan>

            <DescriptionPlan>
              Também é uma opção de correção para aqueles que já dominam a
              estrutura do texto, mas precisam de orientação profissional para
              aperfeiçoar a escrita.
            </DescriptionPlan>

            <ItensPlan>
              <p>• Correção ao vivo por uma videochamada</p>
              <p>• Acesso a técnicas e a dicas exclusivas</p>
              <p>• Esclarecimento de dúvidas pelo WhatsApp</p>
              <p>• 4 mentorias por mês</p>
            </ItensPlan>

            <PricePlan>
              <p>R$ 180,00 / mês</p>
            </PricePlan>
          </Card>
        </Grid>
      </Grid>
    </ContainerPlans>
  );
}
